import moment from "moment";

export const normalize = (prop) => {
  return prop
    ?.toLowerCase()
    ?.replace(/\s+/g, "")
    ?.replace(/\//g, "")
    ?.replace(/-/g, "");
};

let generatedNumbers = new Set();

export const randomNumber = () => {
  let randomNumber;
  do {
    randomNumber = Math.random();
  } while (generatedNumbers?.has(randomNumber));

  generatedNumbers?.add(randomNumber);
  return randomNumber;
};

export const setCookies = (keyName, value) => {
  const cookie = [keyName, "=", JSON.stringify(value)]?.join("");
  document.cookie = cookie;
};

export const getCookie = (name) => {
  var result = document.cookie.match(new RegExp(name + "=([^;]+)"));
  result && (result = JSON.parse(result[1]));
  return result;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const extractFirstWord = (inputString) => {
  const words = inputString?.split(" ");
  return words[0];
};

export const capitalize = (word) => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1);
};

export const camelCase = (word) => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
};

export const parseRouteString = (route, unCapitalize) => {
  const words = route?.split("/")?.filter((word) => word !== "");
  let link = "";
  return words?.map((word) => {
    const capitalizedWord =
      word?.charAt(0)?.toUpperCase() +
      word?.slice(1)?.replace(/([A-Z])/g, " $1");
    const unCapitalizedWord =
      word?.charAt(0) +
      word
        ?.slice(1)
        ?.replace(/([A-Z])/g, "$1")
        ?.trim();
    link += `/${word}`;
    return {
      text: unCapitalize ? unCapitalizedWord : capitalizedWord,
      link: link,
    };
  });
};

export const setLocalStorageData = (key, value) => {
  if (typeof window !== "undefined") {
    if (typeof value === "string") {
      window?.localStorage?.setItem(key, value);
    } else {
      window?.localStorage?.setItem(key, JSON.stringify(value));
    }
  }
};

export const getLocalStorageData = (key) => {
  if (typeof window !== "undefined") {
    if (typeof key !== "undefined") {
      const data = window?.localStorage?.getItem(key);
      if (typeof data === "string") {
        return data;
      } else return JSON.parse(data);
    }
  }
};

export const getSessionStorage = (key) => {
  if (typeof window !== "undefined") {
    if (typeof key !== "undefined") {
      const data = window?.sessionStorage?.getItem(key);
      if (typeof data === "string") {
        return data;
      } else return JSON.parse(data);
    }
  }
};

export const setSessionStorage = (key, value) => {
  if (typeof window !== "undefined") {
    if (typeof value === "string") {
      window?.sessionStorage?.setItem(key, value);
    } else {
      window?.sessionStorage?.setItem(key, JSON.stringify(value));
    }
  }
};

export const getDateFormat = (date, type) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1)?.padStart(2, "0");
  const day = String(date.getDate())?.padStart(2, "0");
  const hours = String(date.getHours())?.padStart(2, "0");
  const minutes = String(date.getMinutes())?.padStart(2, "0");
  const seconds = String(date.getSeconds())?.padStart(2, "0");
  if (type) {
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

export const fetchOrSaveLocalizeValue = (
  countryData,
  localeCode,
  isFetch = false
) => {
  if (!countryData instanceof Array) {
    countryData = [];
  }
  const filteredRegionData = countryData?.filter((obj) =>
    obj?.countryDetails?.some(
      (item) =>
        item["countryCode"]?.replace(/\s/g, "")?.toLowerCase() ===
        localeCode?.[1]?.toLowerCase()
    )
  )[0];
  const filteredCountryData = filteredRegionData?.countryDetails?.filter(
    (obj) =>
      obj?.countryCode?.replace(/\s/g, "")?.toLowerCase() ===
      localeCode?.[1]?.toLowerCase()
  )[0];
  const filteredLanguageData = filteredCountryData?.languageDetails?.filter(
    (obj) =>
      obj?.languageCode?.replace(/\s/g, "")?.toLowerCase() === localeCode?.[0]
  )[0];
  let cookieData;
  if (
    (typeof countryData === "undefined" || countryData?.length === 0) &&
    localeCode?.[0]?.toLowerCase() === "en" &&
    localeCode?.[1]?.toLowerCase() === "th"
  ) {
    cookieData = {
      countryCode: "TH",
      countryName: "Thailand",
      languageCode: "EN",
      languageName: "English",
      regionCode: "APAC",
      countryNameEN: "Thailand",
    };
  } else {
    cookieData = {
      ...filteredLanguageData,
      countryCode: filteredCountryData?.countryCode?.replace(/\s/g, ""),
      regionCode: filteredRegionData?.regionCode,
      countryNameEN: filteredCountryData?.languageDetails?.[filteredCountryData?.languageDetails?.findIndex((iz)=> iz?.languageCode?.toLowerCase()==="en")]?.countryName,
    };
  }
  setCookies("config", {
    countryCode: cookieData?.countryCode,
    languageCode: cookieData?.languageCode,
    regionCode: cookieData?.regionCode,
    countryNameEN: cookieData?.countryNameEN,
  });
  if (isFetch) {
    return cookieData;
  }
};

export const removeLangFromRoute = (route) => {
  const parts = route?.split("/");
  if (parts.length > 2) {
    parts?.splice(1, 1);
  } else {
    return parts[0] + "/";
  }
  return parts?.join("/");
};
//This function ensures to remove duplicates based on the data passed and the unique field passed.
export const removeDuplicateJsonObjects = (data, field) => {
  if (data) {
    const updatedData = data?.reduce((acc, curr) => {
      if (!acc.find((item) => item[field] === curr[field])) {
        acc?.push(curr);
      }
      return acc;
    }, []);
    return updatedData;
  }
};

export const updateRecentSearchState = (
  setArray,
  key,
  fieldName,
  updatedData,
  duplicateCheck,
  maxLength
) => {
  setArray((prevItems) => {
    const item = JSON.parse(getLocalStorageData(key));
    let newItem;
    let isRecentSearchDataExists;
    const dataToUpdate = fieldName ? updatedData[fieldName] : updatedData;
    const isDataExists = item?.find(
      (x) => x?.airportCode === dataToUpdate?.airportCode
    );

    if (!duplicateCheck) {
      isRecentSearchDataExists = item?.find(
        (x) =>
          x?.flightNum === dataToUpdate?.flightNum &&
          x?.departureDate === dataToUpdate?.departureDate
      );
    }
    if (
      (duplicateCheck && !isDataExists) ||
      (!duplicateCheck && !isRecentSearchDataExists)
    ) {
      if (prevItems.length >= maxLength) {
        newItem = [...item.slice(1), dataToUpdate];
      } else {
        newItem = [...item, dataToUpdate];
      }
    } else {
      newItem = [...item];
    }

    localStorage.setItem(key, JSON.stringify(newItem));
    return newItem;
  });
};

export const extractSubArray = (array, step) => {
  const result = [];
  const length = array?.length;

  if (step === 1) {
    return array;
  } else if (step === 2) {
    for (let i = 0; i < length; i++) {
      const subarray = [];
      subarray?.push(array[i]);
      subarray?.push(array[(i + 1) % length]);
      result?.push(subarray);
    }
  } else {
    for (let i = 0; i < length; i += step - 1) {
      const subarray = [];
      if (step > 2) {
        for (let j = 0; j < step; j++) {
          if (j === step - 1) {
            const obj = array[(i + j) % length];
            subarray?.push({ image: obj?.image, altText: obj?.altText });
          } else {
            subarray?.push(array[(i + j) % length]);
          }
        }
      } else {
        for (let j = 0; j < step; j++) {
          subarray?.push(array[(i + j) % length]);
        }
      }
      result?.push(subarray);
    }
  }

  return result;
};

export const reverseJsonArray = (arr) => {
  const reverseArray = [];
  for (let i = arr?.length - 1; i >= 0; i--) {
    reverseArray?.push(arr[i]);
  }
  return reverseArray;
};

export const validateDepartDate = (date) => {
  const now = moment()?.startOf("day");
  const momentDepartDate = moment(date)?.startOf("day");
  return momentDepartDate?.isBefore(now);
};

export const getApiBaseUrl = (flag) => {
  var rootdomain = window.location.origin;
  if (rootdomain.includes("localhost") || rootdomain.includes("file:/")) {
    return flag == "pss"
      ? process.env.REACT_APP_TG_AMADEUS_URL
      : process.env.REACT_APP_TG_API_BASE_URL;
  } else if (rootdomain.includes("thaiairways.com")) {  
    rootdomain = "https://osci.thaiairways.com/";
    return flag == "pss"
      ? "https://osci.thaiairways.com" + "/pss_router/"
      : rootdomain;
  } else {
    return flag == "pss" ? rootdomain + "/pss_router/" : rootdomain + "/";
  }
};

export const handleAmadeusRedirection = (url, wRef) => {
  url = getApiBaseUrl("pss") + url;
  if (wRef?.current) {
    if (wRef?.current && !wRef?.current?.closed) {
      wRef?.current?.location?.reload();
      wRef?.current?.focus();
    } else {
      wRef.current = window?.open(url, "_blank");
    }
  } else {
    window?.open(url, "_blank");
  }
};

export const isUserLoggedIn = () => {
  const accessToken = localStorage.getItem("accesstoken");
  const memberId = sessionStorage.getItem("memberId");

  if (accessToken && memberId) {
    return true;
  } else {
    return false;
  }
};

export const getTierImg = (name) => {
  switch (name) {
    case "member":
      return "/osci/assets/member_tier.png";
    case "silver":
      return "/osci/assets/silver_tier.png";
    case "gold":
      return "/osci/assets/gold_tier.png";
    case "platinum":
      return "/osci/assets/platinum_tier.png";
    default:
      return null;
  }
};

export const getCardImg = (name) => {
  switch (name) {
    case "member":
      return "/osci/assets/silverCard.png";
    case "silver":
      return "/osci/assets/goldCard.png";
    case "gold":
      return "/osci/assets/platinumCard.png";
    default:
      return null;
  }
};
export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex?.test(email);
};

export const isValidWebsite = (website) => {
  const regex =
    /^(?:http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  return regex?.test(website);
};

export const blockSpecialChar = (str) => {
  const regex = /^[A-Za-z0-9]{0,100}$/;
  const isValid = regex?.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const crmPasswordPattern = (str) => {
  const regex = /^[A-Za-z\d@#!$%^&+=]{0,25}$/;
  const isValid = regex?.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const isValidBaggageNum = (str) => {
  const regex = /^[a-zA-Z]{0,2}[0-9]{0,6}$/;
  const isValid = regex?.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const isValidRopId = (str) => {
  return (
    /^[a-zA-Z]{0,2}[0-9]{0,5}$/.test(str) &&
    /^[a-zA-Z]/.test(str[0]) &&
    /^[a-zA-Z]/.test(str[1])
  );
};

export const isValidEventCode = (str) => {
  return (
    /^[a-zA-Z]{0,2}[0-9]{0,7}$/.test(str) &&
    /^[a-zA-Z]/.test(str[0]) &&
    /^[a-zA-Z]/.test(str[1])
  );
};

export const isvalidAlphabet = (str) => {
  return /^[a-zA-Z\s]{0,100}$/.test(str);
};

export const isvalidContact = (str) => {
  return /^[0-9-]{0,18}$/.test(str);
};
export const isvalidAddress = (str) => {
  return /^[a-zA-Z0-9\s/-]{0,100}$/.test(str);
};
export const isvalidLanguage = (str) => {
  return /^[A-Za-z0-9\s\W]*$/.test(str);
};

export const isValidPostal = (str) => {
  const regex = /^[A-Za-z0-9 ]{0,10}$/;
  const isValid = regex?.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const formatDate = (date, type) => {
  if (type) {
    return date?.format("ddd, DD MMM YYYY");
  } else {
    return date?.format("DDMMYY");
  }
};

export const formatNumber = (num) => {
  if (!num) return "";
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const displayDateFormat = (date) => {
  return date?.format("D MMMM YYYY");
};

export const normalDateFormat = (date) => {
  return date?.format("DD MMM YYYY");
};
export const formatContact = (str) => {
  // return str?.replace(/(\d{2})(\d{3})(\d{4})?(\d{5})/, "$1-$2-$3$4");
  return str?.replaceAll("-", "");
};

export const isValidBookingRef = (str) => {
  const string = /^[A-Za-z0-9\s]+$/.test(str);
  const number = /^[1-9\s]*$/.test(str);
  return string || number;
};
export const isNumberOnly = (str) => {
  const number = /^[0-9\s]*$/.test(str);
  return number;
};
export const isNumberDecimalOnly = (str) => {
  const number = /^\d*\.?\d{0,2}$/.test(str);
  return number;
};
export const isNumberDecimalLimitOnly = (str) => {
  const number = /^\d{0,3}(\.\d{0,2})?$/.test(str);
  return number;
};
export const isNumberNoSpacOnly = (str) => {
  const number = /^[0-9]*$/.test(str);
  return number;
};
export const isNumberCommaDotOnly = (str) => {
  const doubleZero = /^00/.test(str);
  const number = /^[0-9,]*$/.test(str);
  return number && !doubleZero;
};

export const isValidSeatNo = (str) => {
  return /^[a-zA-Z]{0,1}[0-9]{0,3}$/.test(str);
};
export const isMiceblockSpecialChar = (str) => {
  const regex = /^[A-Za-z0-9]{0,200}$/;
  const isValid = regex?.test(str);
  if (isValid) {
    return true;
  } else return false;
};

export const isMiceEventName = (str) => {
  const number = /^[a-zA-Z0-9 ]{5,15}$/.test(str);
  return number;
};

export const checkValidity = (validationKey, enteredText) => {
  switch (validationKey) {
    case "seatNo":
      return isValidSeatNo(enteredText);
    case "rop":
      return isValidRopId(enteredText);
    case "name":
      return isvalidAlphabet(enteredText);
    case "email":
      return isValidEmail(enteredText);
    case "contact":
      return isvalidContact(enteredText);
    case "noSpecialChar":
      return blockSpecialChar(enteredText);
    case "postal":
      return isValidPostal(enteredText);
    case "bookingRef":
      return isValidBookingRef(enteredText);
    case "address":
      return isvalidAddress(enteredText);
    case "language":
      return isvalidLanguage(enteredText);
    case "number":
      return isNumberOnly(enteredText);
    case "numberNoSpace":
      return isNumberNoSpacOnly(enteredText);
    case "numberComma":
      return isNumberCommaDotOnly(enteredText);
    case "decimal":
      return isNumberDecimalOnly(enteredText);
    case "numberLimit":
      return isNumberDecimalLimitOnly(enteredText);
    case "eventCode":
      return isValidEventCode(enteredText);
    case "miceblockSpecialChar":
      return isMiceblockSpecialChar(enteredText);
    case "miceEventName":
      return isMiceEventName(enteredText);
    case "crmPasswordPattern":
      return crmPasswordPattern(enteredText);
    case "isValidBaggageNum":
      return isValidBaggageNum(enteredText);
    default:
      return true;
  }
};

export const updateRecentSearchFlightBooking = (
  setArray,
  key,
  fieldName,
  updatedData,
  duplicateCheck,
  maxLength
) => {
  setArray((prevItems) => {
    const item = JSON.parse(getLocalStorageData(key));
    let newItem;
    let isRecentSearchDataExists;
    const dataToUpdate = fieldName ? updatedData[fieldName] : updatedData;
    const isDataExists = item.find(
      (x) => x.airportCode === dataToUpdate?.airportCode
    );
    if (!duplicateCheck) {
      isRecentSearchDataExists = item?.find(
        (x) =>
          x?.from?.airportCode === dataToUpdate?.from?.airportCode &&
          x?.to?.airportCode === dataToUpdate?.to?.airportCode &&
          x?.depart === dataToUpdate?.depart &&
          x?.return === dataToUpdate?.return &&
          x?.class === dataToUpdate?.class &&
          x?.type === dataToUpdate?.type &&
          x?.passengers?.adult === dataToUpdate?.passengers?.adult &&
          x?.passengers?.youth === dataToUpdate?.passengers?.youth &&
          x?.passengers?.child === dataToUpdate?.passengers?.child &&
          x?.passengers?.infant === dataToUpdate?.passengers?.infant
      );
    }
    if (
      (duplicateCheck && !isDataExists) ||
      (!duplicateCheck && !isRecentSearchDataExists)
    ) {
      if (prevItems.length >= maxLength) {
        newItem = [...item.slice(1), dataToUpdate];
      } else {
        newItem = [...item, dataToUpdate];
      }
    } else {
      newItem = [...item];
    }

    localStorage.setItem(key, JSON.stringify(newItem));
    return newItem;
  });
};

export const updateRecentSearchFlightStatus = (
  setArray,
  key,
  fieldName,
  updatedData,
  maxLength
) => {
  setArray((prevItems) => {
    const item = JSON.parse(getLocalStorageData(key));
    const length = item?.length ?? 0;
    let newItem;
    let isRecentSearchDataExists;
    // let isFollowedFlightExists;
    let isFirstElement = true;
    let dataToUpdate = fieldName ? updatedData[fieldName] : updatedData;

    dataToUpdate.id = length + 1;
    if (item?.length > 0) {
      isFirstElement = false;
      if (dataToUpdate.type === "Flight Number") {
        isRecentSearchDataExists = item?.find((x) => {
          return (
            x?.departureDate === dataToUpdate?.departureDate &&
            x?.flightNum === dataToUpdate?.flightNum &&
            x?.type === "Flight Number"
          );
        });
      } else {
        isRecentSearchDataExists = item?.find(
          (x) =>
            x?.type === "Route" &&
            moment(x?.departureDate)?.format("DDMMYY") ===
              moment(dataToUpdate?.departureDate)?.format("DDMMYY") &&
            x?.flightNum === dataToUpdate?.flightNum
        );
      }
    } else {
      isRecentSearchDataExists = false;
      isFirstElement = true;
    }
    if (isFirstElement) {
      newItem = [dataToUpdate];
    } else if (!isRecentSearchDataExists) {
      if (prevItems?.length >= maxLength) {
        newItem = [...item.slice(1), dataToUpdate];
      } else {
        newItem = [...item, dataToUpdate];
      }
    } else {
      newItem = [...item];
    }
    isRecentSearchDataExists = false;
    setLocalStorageData(key, JSON.stringify(newItem));
    return newItem;
  });
};

export const isEmptyChecker = (value) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value?.trim() === "") ||
    (Array.isArray(value) && value?.length === 0) ||
    (typeof value === "object" &&
      !Array.isArray(value) &&
      Object.keys(value)?.length === 0)
  );
};

export const DateFormatter = (date) => {
  const dateParts = date?.split("-");
  return new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[2])
  );
};

export const DateToDayConverter = (date) => {
  return moment(date)?.format("ddd DD, MMM YYYY");
};
export const calculateTimeLeft = (endTime) => {
  const remainingTime = endTime - Date.now();
  return {
    total: remainingTime,
    minutes: Math.floor(remainingTime / 1000 / 60),
    seconds: Math.floor((remainingTime / 1000) % 60),
  };
};

export const formatPriceInternational = (value) => {
  const formattedNumber = new Intl.NumberFormat("en-US")?.format(value);
  return formattedNumber;
};

export const handleClearDetails = () => {
  sessionStorage.clear();
  localStorage.removeItem("isROPLoggedIn");
  localStorage.removeItem("isCRMLoggedIn");
  localStorage.removeItem("accesstoken");
};

export const processJsonWithHtmlContent = (json, fieldNames) => {
  return json.map((item) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = item?.label;
    const fields = tempContainer?.children;
    const rowData = {};
    Array.from(fields)?.forEach((field, index) => {
      const key = fieldNames[index]?.label;
      const value = field?.textContent?.trim();
      if (key) {
        rowData[key] = value;
      }
    });
    return rowData;
  });
};

export const processJsonWithHtmlContentVariant = (json, fieldNames) => {
  return json.map((item) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = item?.label;
    const fields = tempContainer?.children;
    const rowData = {};
    Array.from(fields)?.forEach((field, index) => {
      if (index <= fieldNames.length - 1) {
        const key = fieldNames[index]?.label;
        const value = field?.textContent?.trim();
        if (key) {
          rowData[key] = value;
        }
      }
    });
    return rowData;
  });
};

export const mergeTwoAirportObjects = (json1, json2, field, filterName) => {
  const mergedArray = json1?.map((item1) => {
    const matchedItem = json2?.find((item2) => item2[field] === item1[field]);
    return { ...item1, ...(matchedItem || {}) };
  });

  const updatedData = mergedArray?.filter(
    (data) => data?.[filterName] === true
  );
  return updatedData;
};

export const getKeyByValue = (object, value) => {
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) {
        return prop;
      }
    }
  }
};

export const getFlightType = (value) => {
  switch (value) {
    case "Multi-City":
      return "M";
    case "One-way":
      return "O";
    case "Return":
      return "R";
    default:
      break;
  }
};

export const displayRoutes = (arr) => {
  let routeStrings = arr?.map(
    ({ from, to }) => `${from?.airportCode} - ${to?.airportCode}`
  );
  let finalRoute = routeStrings[0] || ""; // Start with the first route

  for (let i = 1; i < routeStrings?.length; i++) {
    let prevRoute = finalRoute?.split(" - ");
    let currRoute = routeStrings[i]?.split(" - ");

    // If previous destination matches current origin, merge
    if (prevRoute[prevRoute.length - 1] === currRoute[0]) {
      finalRoute += " - " + currRoute[1]; // Append only the destination
    } else {
      finalRoute += " // " + routeStrings[i]; // Separate the routes
    }
  }
  // Format and print output
  return finalRoute;
};

export const formattedLanguageCountryCode = (i18n) => {
  return process.env.REACT_APP_LEGACY_COEXIST
    ? i18n?.language?.split("-")[0] +
        "_" +
        i18n?.language?.split("-")[1]?.toUpperCase()
    : i18n?.language;
};

export const pickFieldsFromObjects = (obj, fields) =>
  fields?.reduce((newObj, field) => {
    if (obj[field] !== undefined) newObj[field] = obj[field];
    return newObj;
  }, {});

export const updateArray = (arr, newObj, index) => {
  if (arr.length > index) {
    arr[index] = newObj;
  } else {
    arr?.push(newObj);
  }
  return arr;
};

export const parseCustomDateTime = (timeStr, dateStr, timeZone) => {
  const offset = timeZone?.replace("UTC", "")?.replace("GMT", "")?.trim();
  const formattedOffset = offset?.replace(/^(\+|-)?(\d)$/, "$1" + "0$2" + ":00");
  return moment(`${dateStr}${timeStr}`, "DDMMYYHHmm")?.utcOffset(
    formattedOffset
  );
};

export const dataLayerGetter = (eventkey) => {
  return window?.dataLayer?.filter((item) => item?.event === eventkey);
};

export const formatNames = (nameString) => {
  const names = nameString?.split(" ");

  const formattedNames = names?.map((name) => {
    return name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase();
  });

  return formattedNames?.join(" ");
};

export const genderNameValidation = (fullname) => {
  let lowerCaseFullname = fullname?.toLowerCase();
  if (lowerCaseFullname?.includes("prefer not to say")) {
    lowerCaseFullname = lowerCaseFullname?.replace(/prefer not to say/i, "");
  }

  return formatNames(lowerCaseFullname);
};

export const formatPhoneNumberDisplay = (phoneNumber) => {
  const parts = phoneNumber?.split("-");
  if (parts?.length > 2 && /^0+$/.test(parts[1])) {
    return `${parts[0]}-${parts[2]}`;
  } else {
    return phoneNumber;
  }
};

export const fetchAmadeusSupportedLanguage = (lngCode) => {
  switch (lngCode) {
    case "en":
    case "zh":
    case "vn":
    case "sv":
    case "tr":
      return "gb";
    case "ja":
      return "jp";
    default:
      return lngCode;
  }
};

export const getFullName = (salutation, firstName, middleName, lastName) => {
  if (!isEmptyChecker(middleName)) {
    const fullName = `${salutation} ${firstName} ${middleName} ${lastName}`;
    return genderNameValidation(fullName);
  } else {
    const fullName = `${salutation} ${firstName} ${lastName}`;
    return genderNameValidation(fullName);
  }
};

export const slashFormatter = (value) => {
  return value?.replace(/-/g, "/");
};

export const getLanguageCountry = (url) => {
  const updatedUrl = url?.toLowerCase();
  const regex = /(?:\/|^)([a-z]{2}-[a-z]{2})(?:\/|$)/;
  const decodedUrl = decodeURIComponent(updatedUrl); // Matches 'en-US', 'fr-FR', etc.
  const match = decodedUrl?.match(regex);
  return match ? match[1] : null;
};

export const blackoutDateExtracter = (ranges) => {
  try {
    if (typeof ranges !== "string") {
      if (Array.isArray(ranges)) {
        ranges = JSON.stringify(ranges);
      } else {
        throw new Error("Invalid input: Expected a JSON string or an array.");
      }
    }
    ranges = ranges?.replace(/'/g, '"');

    ranges = JSON.parse(ranges);

    if (!Array.isArray(ranges)) {
      throw new Error("Invalid data format. Expected an array.");
    }
    const result = ranges?.flatMap(([start, end]) => {
      let currentDate = new Date(start?.split("-")?.reverse()?.join("-"));
      let endDate = new Date(end?.split("-")?.reverse()?.join("-"));
      let dates = [];

      while (currentDate <= endDate) {
        dates?.push(currentDate?.toISOString()?.split("T")[0]); // Convert to YYYY-MM-DD
        currentDate?.setDate(currentDate?.getDate() + 1);
      }
      return dates;
    });

    return result;
  } catch (error) {
    return [];
  }
};

export const getAEMImageRootPath = () => {
  let baseURL = window?.location?.origin+"/";
  if(window?.location?.origin?.includes("localhost")){
    baseURL = "https://tgosci-sit.com/"
  }
  return baseURL;
}

export const formatAmount = (num) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    return num.toString();
  }
 }