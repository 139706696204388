// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rHRZpeOgbYxm9wagFT5G{height:100dvh}.rHRZpeOgbYxm9wagFT5G>div{width:max-content;margin:auto;background:unset;border:unset;height:100%;display:flex;align-items:center}.rHRZpeOgbYxm9wagFT5G>div>div{width:max-content;background:none;border:unset}.JWfk_u5keKyncsXJA1w1{opacity:.5}`, "",{"version":3,"sources":["webpack://./src/shared/tg-main-loader/tg-main-loader.module.scss"],"names":[],"mappings":"AAAA,sBACI,aAAA,CAEA,0BACI,iBAAA,CACA,WAAA,CACA,gBAAA,CACA,YAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAEA,8BACI,iBAAA,CACA,eAAA,CACA,YAAA,CAKZ,sBACI,UAAA","sourcesContent":[".loader-container {\n    height: 100dvh;\n\n    >div {\n        width: max-content;\n        margin: auto;\n        background: unset;\n        border: unset;\n        height: 100%;\n        display: flex;\n        align-items: center;\n\n        >div {\n            width: max-content;\n            background: none;\n            border: unset;\n        }\n    }\n}\n\n.loader-backdrop {\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `rHRZpeOgbYxm9wagFT5G`,
	"loader-backdrop": `JWfk_u5keKyncsXJA1w1`
};
export default ___CSS_LOADER_EXPORT___;
